<template>
  <v-container fluid>

    <v-card tile>

      <v-card-title>
        Users
        <v-spacer></v-spacer>
        <v-text-field
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!--        <v-spacer></v-spacer>-->
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn @click="$router.push('/admin/users/new')" class="primary">New item</v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.placeOfBirth="{ item }">
          <small>{{ item.placeOfBirth }}</small>
        </template>

        <template v-slot:item.permanentAddress="{ item }">
          <small>{{ item.permanentAddress }}</small>
        </template>

        <template v-slot:item.registrationDatetime="{ item }">
          {{ new Intl.DateTimeFormat('cs-CZ', { dateStyle: 'short', timeStyle: 'short' })
          .format(new Date(item.registrationDatetime)) }}
        </template>

        <template v-slot:item.active="{ item }">
          <v-switch
            v-model="item.active"
            @change="switchItemActiveState(item)"
            color="success"
          ></v-switch>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon
                  color="blue"
                  @click="openResetUserPasswordDialog(item)"
                >
                  mdi-lock-reset
                </v-icon>
              </v-btn>
            </template>
            <span>Reset password</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon
                  color="red"
                  @click="downloadUserRegistrationForm(item.id)"
                >
                  mdi-file-document-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Generate PDF</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon
                  color="blue"
                  @click="editItem(item.id)"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon
                  color="red"
                  @click="openDeleteItemDialog(item)"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>

        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="deleteItemDialog"
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteItemDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteItem">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="resetUserPasswordDialog"
      max-width="650px"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-card-title class="headline">Are you sure you want to reset password for this user?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetUserPasswordDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="resetUserPassword">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>

</template>

<script>
export default {
  data () {
    return {
      path: 'users',
      items: [],
      item: null,
      deleteItemDialog: false,
      resetUserPasswordDialog: false,
      loading: true,
      search: '',
      headers: [
        { text: 'UID', value: 'id' },
        { text: 'NAME', value: 'name', filterable: true },
        { text: 'SURNAME', value: 'surname' },
        { text: 'USERNAME', value: 'username' },
        { text: 'E-MAIL', value: 'email' },
        { text: 'DATE OF BIRTH', value: 'dateOfBirth' },
        { text: 'PLACE OF BIRTH', value: 'placeOfBirth' },
        { text: 'PERMANENT ADDRESS', value: 'permanentAddress' },
        { text: 'REGISTRATION DATETIME', value: 'registrationDatetime' },
        { text: 'ROOM NUMBER', value: 'roomNumber' },
        { text: 'ACTIVE', value: 'active' },
        { text: 'ACTIONS', value: 'actions', sortable: false, width: '200px' }
      ]
    }
  },

  methods: {
    editItem (id) {
      this.$router.push(`/admin/${this.path}/edit?id=${id}`)
    },
    async switchItemActiveState (item) {
      await this.$store.dispatch('rest/updateItem', {
        path: this.path,
        item: item
      })
    },
    async downloadUserRegistrationForm (id) {
      // this.$router.push(process.env.VUE_APP_SERVER_URL + `/admin/users/${id}/pdf`)
      await this.$store.dispatch('user/downloadUserRegistrationForm', id)
    },
    async deleteItem () {
      await this.$store.dispatch('rest/deleteItem', {
        path: this.path,
        id: this.item.id
      })
      this.items.splice(this.items.indexOf(this.item), 1)
      this.item = null
      this.deleteItemDialog = false
    },
    async resetUserPassword () {
      await this.$store.dispatch('user/resetUserPassword', this.item.id)
      this.resetUserPasswordDialog = false
      this.item = null
    },
    openDeleteItemDialog (item) {
      this.item = item
      this.deleteItemDialog = true
    },
    openResetUserPasswordDialog (item) {
      this.item = item
      this.resetUserPasswordDialog = true
    }
  },

  async mounted () {
    document.title = 'Users | Masarka Student Club'
    this.loading = true
    this.items = await this.$store.dispatch('rest/fetchAllItems', { path: this.path })
    this.loading = false
  }
}
</script>

<style scoped>

</style>
